<template>
  <tbody class='border-t'>
    <tr class='hover:bg-gray-50 cursor-pointer hover:shadow-md'
      @click='toggleOpenDetail'>
      <td class='py-2 lg:py-4 px-2 uppercase'><chevron-double-right-icon size='1.2x'/></td>
      <td class='py-2 lg:py-4 px-2 uppercase font-semibold'>{{sponsorsByLevel.levelName}}<span class='text-sm'> (show details)</span></td>
      <td class='py-2 lg:py-4 px-2'></td>   
      <td class='py-2 lg:py-4 px-2 text-right'>{{ progressString }}</td>     
    </tr>
    <tr v-for='sponsor in sponsorStampedData'
      :key='`sponsor-${sponsor.id}`'
      class='text-sm' >
      <td class='py-2 px-2 uppercase'></td>
      <td class='py-2 px-2 text-sm'>{{ sponsor.name }}</td>
      <td class='py-2 px-2 text-right uppercase'>
        <component :is='quizCompletedIcon(sponsor.quizCompleted)'
          class='inline-block'
          :style='quizCompletedIconStyle(sponsor.quizCompleted)'></component>
        {{ quizCompletedString(sponsor.quizCompleted) }}
      </td>
      <td class='py-2 px-2 text-right'>{{ quizCompletedDateTimeString(sponsor.quizCompletedAt) }}</td>
    </tr>
  </tbody>
</template>

<script>
import { mapGetters } from 'vuex'
import { ChevronDoubleRightIcon } from '@vue-hero-icons/outline'
import { CircleDashedIcon, CircleCheckIcon } from 'vue-tabler-icons'
import DateTimeStringHelpers from '@/utils/date-time-string-helpers'

export default {
  name: 'MyPageQuizRow',
  props: [
    'sponsorsByLevel'
  ],
  data () {
    return {
      showDetails: false,
    }
  },
  components: {
    ChevronDoubleRightIcon,
    CircleDashedIcon,
    CircleCheckIcon
  },
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
    sponsorStampedData () {
      return (this.showDetails) ? this. sponsorsByLevel.listOfSponsors : []
    },
    quizCompletedSponsor () {
      return this.sponsorsByLevel.listOfSponsors.filter((sponsor) => sponsor.quizCompleted)
    },
    progressString () {
      return `${this.quizCompletedSponsor.length} of ${this.sponsorsByLevel.listOfSponsors.length}`
    },
  },
  methods: {
    quizCompletedDateTimeString (date) {
      return date ? DateTimeStringHelpers.toDateTime(date) : ''
    },
    toggleOpenDetail () {
      this.showDetails = !this.showDetails
    },
    quizCompletedIconStyle (quizCompleted) {
      return quizCompleted ? `margin-right: 0.5rem; color: ${this.eventMainThemeColor};` : 'margin-right: 0.25rem'
    },
    quizCompletedString (quizCompleted) {
      return quizCompleted ? 'completed' : 'incomplete'
    },
    quizCompletedIcon (quizCompleted) {
      return quizCompleted ? 'Circle-check-icon' : 'CircleDashedIcon'
    },
  },
}
</script>

